:root {
  --doc-height: 100%;
  height: 100%;
}
html,
body {
  padding: 0;
  margin: 0;
  height: 100vh; /* fallback for Js load */
  height: var(--doc-height);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-scrollbar-track {
  background-color: #212121;
  border-radius: 16px;
}

::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 16px;
  border: 3px solid #212121;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-button {
  display: none;
}

@media screen and (max-width: 768px) {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    display: none;
  }
}

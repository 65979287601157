body{
  background-color: black;
}

:root{
  background-color: black;
}

@font-face {
  font-family: 'BulgariBold';
  src: url('./assets/fonts/Bulgari_Type_Bold.eot');
  src: url('./assets/fonts/Bulgari_Type_Bold.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Bulgari_Type_Bold.woff') format('woff'),
    url('./assets/fonts/Bulgari_Type_Bold.woff2') format('woff2'),
    url('./assets/fonts/Bulgari_Type_Bold.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'BulgariItalic';
  src: url('./assets/fonts/Bulgari_Type_Italic.eot');
  src: url('./assets/fonts/Bulgari_Type_Italic.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Bulgari_Type_Italic.woff') format('woff'),
    url('./assets/fonts/Bulgari_Type_Italic.woff2') format('woff2'),
    url('./assets/fonts/Bulgari_Type_Italic.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'BulgariItalicLight';
  src: url('./assets/fonts/Bulgari_Type_Light_Italic.eot');
  src: url('./assets/fonts/Bulgari_Type_Light_Italic.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Bulgari_Type_Light_Italic.woff') format('woff'),
    url('./assets/fonts/Bulgari_Type_Light_Italic.woff2') format('woff2'),
    url('./assets/fonts/Bulgari_Type_Light_Italic.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'BulgariLight';
  src: url('./assets/fonts/Bulgari_Type_Light.eot');
  src: url('./assets/fonts/Bulgari_Type_Light.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Bulgari_Type_Light.woff') format('woff'),
    url('./assets/fonts/Bulgari_Type_Light.woff2') format('woff2'),
    url('./assets/fonts/Bulgari_Type_Light.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'BulgariRegular';
  src: url('./assets/fonts/Bulgari_Type_Regular.eot');
  src: url('./assets/fonts/Bulgari_Type_Regular.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Bulgari_Type_Regular.woff') format('woff'),
    url('./assets/fonts/Bulgari_Type_Regular.woff2') format('woff2'),
    url('./assets/fonts/Bulgari_Type_Regular.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Futura PT';
  src: url('./assets/fonts/futura-med-webfont.woff') format('woff'),
    url('./assets/fonts/futura-med-webfont.ttf') format('truetype');
}

.App {
  height: 100%;
  text-align: center;
  background: url(./assets/img/bg.webp);
  background-repeat: no-repeat;
  background-size: auto;
  background-size: cover;
}

.App-logo {
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/**** Welcome Message ****/

.welcome-message {
  justify-content: center;
  display: flex;
  align-items: center;
  pointer-events: none;
}

.welcome-message p {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: black;
  font-family: 'BulgariRegular';
  font-size: 980px;
  font-weight: 400;
  text-transform: uppercase;
}

/**** Dialog ****/

.detail-container {
  display: flex;
  padding: 40px 24px 40px 40px;
  margin-top: 16px;
  overflow: hidden;
  flex-direction: row;
  height: 100%;
}

@media (max-width: 800px) {
  .detail-container {
    display: flex;
    padding: 48px;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
  }
}

.detail-image-container {
  flex: 1;
  overflow: hidden;
}

.detail-image-container img {
  height: 100%;
  width: 100%;
  border-radius: 24px;
  object-fit: cover;
}

@media (max-width: 800px) {
  .detail-image-container img {
    border-radius: 24px;
    width: -webkit-fill-available;
    margin: 30px;
  }
}

.horizontal {
  display: flex;
  align-items: center;
  align-self: center;
  flex-direction: row;
}

.detail-slash {
  align-items: center;
  height: 80px;
  width: 80px;
  overflow: hidden;
}

.detail-slash img {
  align-items: center;
  height: 80px;
  width: 80px;
  object-fit: scale-down;
}

.detail-text-container {
  padding-left: 70px;
  padding-right: 40px;
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
}

@media (max-width: 800px) {
  .detail-text-container {
    margin-top: 24px;
    padding-left: 30px;
    padding-right: 30px;
    flex: 1;
    overflow-y: inherit;
  }
}

.detail-text-container .space {
}

.space h1 {
  margin: 0;
  text-align: left;
  color: #ffffff;
  font-family: 'BulgariBold';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
}

.space h2 {
  margin: 0;
  text-align: left;
  color: #ffffff;
  font-family: 'BulgariLight';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 28px;
}

.space p {
  text-align: left;
  color: #ffffff;
  font-family: 'BulgariLight';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
}

.title-section {
  font-family: 'BulgariRegular';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.detail-charateristics {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}

.detail-divider {
  border: 0.5px solid #ffffff;
  width: 100%;
  height: 0px;
  margin-top: 24px;
  margin-bottom: 24px;
}

.detail-divider-light {
  width: 100%;
  height: 0.1px;
  background-color: rgba(255, 255, 255, 0.5);
  margin-top: 24px;
  margin-bottom: 24px;
}

.detail-charateristics-item {
  flex: 1;
  padding-right: 16px;
  text-align: left;
}

.detail-charateristics-item:nth-child(2n) {
  margin-left: 40px;
}

.detail-charateristics-item img {
  height: 24px;
}

.detail-charateristics-item .char-title {
  text-align: left;
  font-family: 'BulgariRegular';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #ffffff;
}

.detail-charateristics-item .char-description {
  text-align: left;
  font-family: 'BulgariLight';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}

.dialog {
  position: fixed;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: hidden;
  overflow-x: hidden;
  cursor: default;
  background-color: #000000;
  width: calc(100vw - 40px);
  height: calc(100vh - 40px);
  border-radius: 20px;
  /*center*/
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dialog .image {
  object-fit: cover;
  width: 80vw;
  height: 70vh;
  border-radius: 24px;
}

.info-item-container {
  flex: 1;
  padding-right: 16px;
  text-align: left;
}

.info-item-title {
  font-family: 'BulgariRegular';
  font-style: normal;
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 28px;
  color: #ffffff;
}

.info-item-subtitle {
  font-family: 'BulgariLight';
  margin: 0;
  font-style: normal;
  font-weight: 300;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}

.info-item-description {
  font-family: 'BulgariLight';
  margin-top: 12px;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
}

/*
.dialog h1 {
  font-family: 'BulgariRegular';
  font-style: normal;
  font-weight: 300;
  font-size: 48px;
  line-height: 67px;
  

  text-align: center;

  color: #ffffff;


  flex: none;
  order: 0;
  flex-grow: 0;
}
*/
.emotion {
  display: flex;
}

.badges {
  display: flex;
  flex-direction: row;
  flex: none;
  justify-content: center;
}

.badge {
  box-sizing: border-box;

  align-items: center;
  margin: 8px;

  width: 141px;

  background: rgba(255, 255, 255, 0.25);
  border: 1px solid #ffffff;
  border-radius: 24px;
}

.badge p {
  font-family: 'BulgariRegular';
  font-style: normal;
  font-weight: 300;

  text-align: center;
  color: #ffffff;
}

.description {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}
.description p {
  font-family: 'Futura PT';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  padding-left: 48px;
  padding-right: 48px;
  color: #ffffff;
  max-width: 720px;
  margin-bottom: 10vh;
  text-align: left;
}

.description .divider {
  width: 40%;
  height: 0px;
  margin-bottom: 24px;
  border: 1px solid rgba(255, 255, 255, 0.75);
}

/******* Loading ********/

.loadingScene {
  /* scale: 1.5; */
  box-shadow: inset 0 0 300px 1000px rgba(0, 0, 0, 1);
  z-index: 9999;
  position: absolute;
  display: flex;
  width: 100vw;
  height: 100vh;
  /* background-color: #826137; */
  background: url(./assets/img/bg.webp);
  background-repeat: no-repeat;
  background-size: auto;
  background-size: cover;
}

.loading-header {
  position: absolute;
  align-items: center;
  padding-top: 16px;
  width: 100vw;
}

.loading-header img {
  height: 16px;
}

.loading-center {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-content: center;
}

.loading-center h2 {
  font-family: 'BulgariRegular';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 48px;
  margin-bottom: 0;
  /* identical to box height */
  text-align: center;
  color: #ffffff;
}

.loading-center h1 {
  font-family: 'BulgariRegular';
  font-style: normal;
  font-weight: 400;
  font-size: 98px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  text-transform: uppercase;
  color: #ffffff;
}

.header-logo {
  position: absolute;
  width: 100px;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
}

.header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding-bottom: 10px;
}

.footer-content {
  background: rgba(255, 255, 255, 0.25);
  /* UI/Neutrals/White */
  height: 48px;
  width: 48px;
  border-radius: 48px;
  padding: 0px 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ffffff;
}

.footer-terms {
  position: absolute;
  bottom: 0;
  right: 2%;
  transform: translateX(-2%);
  padding-bottom: 10px;
  font-family: 'BulgariRegular';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  text-transform: uppercase;
  color: #ffffff;
}

@media (max-width: 800px) {
  .footer-terms{
    position: absolute;
      bottom: 0;
      right: 2%;
      transform: translateX(-2%);
      padding-bottom: 10px;
      font-family: 'BulgariRegular';
      font-style: normal;
      font-weight: 400;
      font-size: 8px;
    
      text-transform: uppercase;
      color: #ffffff;
  }
  .loading-center h2 {
    font-family: 'BulgariRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 48px;
    margin-bottom: 0;
    /* identical to box height */
    text-align: center;
    color: #ffffff;
  }

  .loading-center h1 {
    font-family: 'BulgariRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    text-transform: uppercase;
    color: #ffffff;
  }
}

.loading-center .badge {
}

.dialog-close {
  position: absolute;
  top: 18px;
  right: 18px;
  cursor: pointer;
}

.dialog-blurred {
  filter: blur(4px);
  background-color: #0000007d;
  height: 100vh;
  width: 100vw;
  z-index: 999;
  position: fixed;
}

/* dialog-close on mobile */
@media (max-width: 800px) {
  .dialog {
    position: fixed;
    z-index: 9999;
    display: block;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    overflow-x: hidden;
    cursor: default;
    padding-bottom: 10px;
    width: 90vw;
    height: calc(100% - 120px);
    border-radius: 24px;
    background-color: #000000;
    /*centered horizontal*/
    left: 50%;
    top: 0;
    padding: 10px;
    transform: translate(-50%, 20px);
  }

  .image {
    object-fit: cover;
    width: 100px;
    height: 100px;
    border-radius: 24px;
  }

  .detail-charateristics {
    flex-direction: column;
    display: flex;
    justify-content: space-between;
  }

  .detail-image-container {
    padding-top: 20px;
  }

  .detail-text-container {
    overflow: hidden;
  }

  .info-item-container {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .dialog-close-button {
    position: absolute;
    cursor: pointer;
    left: 50%;
    bottom: 10px;
    transform: translate(-50%, 0px);
    background: rgba(255, 255, 255, 0.25);
    border-radius: 100%;
    z-index: 10000;
    display: flex;
    justify-self: center;
    align-self: center;
    border: 1px solid #ffffff;
    padding: 16px;
  }

  .detail-charateristics-item:nth-child(2n) {
    margin-left: 0px;
  }
}

.up-down-animation {
  animation: up-down 1s ease-in-out infinite;
}

@keyframes up-down {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
